import React from 'react';
import { Link } from 'gatsby';

const Video = props => (
  <article className="space-y-3 relative mt-6 lg:mt-0">
    <Link target="_blank" rel="noopener noreferrer" to={props.path}>
      <img
        className="rounded-lg object-cover h-48 w-full"
        src={props.img}
        alt={props.imgAlt}
      />
      <div className="absolute top-2 right-2 w-1/2">
        <img className={`w-full ${props.drumAward ? 'rounded-md bg-white' : ''}`} src={props.logo} alt={props.logoAlt} />
      </div>
      <div className="absolute top-2 right-2 w-1/4">
        <img
          className="w-full"
          src={props.squareLogo}
          alt={props.squareLogoAlt}
        />
      </div>
      <div className={`absolute ${props.logoTwoClass} right-2 w-1/2`}>
        <img className="w-full" src={props.logoTwo} alt={props.logoAltTwo} />
      </div>
    </Link>
    <div className="flex flex-col justify-between h-28">
      <header>
        <h2 className="pl-2 font-arima english-font hover:underline">
          <Link target="_blank" rel="noopener noreferrer" to={props.path}>
            {props.title}
          </Link>
        </h2>
      </header>
      <div className="space-y-2 mt-2">
        <div className="pl-2 font-poppins font-light english-font text-xs text-lightblue">
          <p>{props.desc}</p>
        </div>
        <div className="flex items-center pl-2 font-poppins english-font text-xs space-x-3 font-light pt-4">
          <p className="italic hover:underline">
            <Link target="_blank" rel="noopener noreferrer" to={props.path}>
              Play
            </Link>
          </p>
          <svg
            className="h-2"
            xmlns="http://www.w3.org/2000/svg"
            width="11.794"
            height="11.428"
            viewBox="0 0 11.794 11.428"
          >
            <g
              id="Polygon_52"
              data-name="Polygon 52"
              transform="translate(7.794 11.428) rotate(-150)"
              fill="none"
            >
              <path d="M4.5,0,9,8H0Z" stroke="none" />
              <path
                d="M 4.5 2.039735317230225 L 1.709851264953613 7 L 7.290148735046387 7 L 4.5 2.039735317230225 M 4.5 0 L 9 8 L 0 8 L 4.5 0 Z"
                stroke="none"
                fill="#1e3d49"
              />
            </g>
          </svg>
        </div>
        {props.showArticle ? (
          <div className="flex items-center pl-2 font-poppins english-font text-xs space-x-3 font-light">
          <p className="italic hover:underline">
            <Link target="_blank" rel="noopener noreferrer" to={props.articlePath}>
              Read article
            </Link>
          </p>
          <svg
            className="h-2"
            xmlns="http://www.w3.org/2000/svg"
            width="11.794"
            height="11.428"
            viewBox="0 0 11.794 11.428"
          >
            <g
              id="Polygon_52"
              data-name="Polygon 52"
              transform="translate(7.794 11.428) rotate(-150)"
              fill="none"
            >
              <path d="M4.5,0,9,8H0Z" stroke="none" />
              <path
                d="M 4.5 2.039735317230225 L 1.709851264953613 7 L 7.290148735046387 7 L 4.5 2.039735317230225 M 4.5 0 L 9 8 L 0 8 L 4.5 0 Z"
                stroke="none"
                fill="#1e3d49"
              />
            </g>
          </svg>
        </div>
        ) : ''}
      </div>
    </div>
  </article>
);

export default Video;
