import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PostLink from '../components/post-link'
import NavBar from '../components/nav'
import WhatWeDo from '../components/WhatWeDo'
import Footer from '../components/Footer'
import $ from 'jquery'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Seo from '../components/Seo'
import Vimeo from '../components/Video'
import IdeasBanner from '../components/IdeasBanner'
import IdeasWhatWeDo from '../components/IdeasWhatWeDo'

const Ideas = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    const Posts = edges
        .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
        .map((edge) => <PostLink key={edge.node.id} post={edge.node} />)

    useEffect(() => {
        AOS.init()
        AOS.refresh()

        $(document).ready(function() {
            $('a').on('click', function(event) {
                if (this.hash !== '') {
                    event.preventDefault()

                    let hash = this.hash

                    $('html, body').animate(
                        {
                            scrollTop: $(hash).offset().top,
                        },
                        800,
                        function() {
                            window.location.hash = hash
                        }
                    )
                }
            })
        })
    }, [])

    return (
        <Layout>
            <Seo
                title={'Ideas'}
                description={
                    'Innovation requires actionable and differentiated ideas - the kind that excite customers and bring new categories and markets into being.'
                }
            />
            <header>
                <NavBar
                    navClass={'white-navbar'}
                    logo={'/assets/main-logo-blue.svg'}
                    class={'text-darkblue border-darkblue'}
                />
                {/* HEADER */}
                <IdeasBanner />
            </header>
            <main id="main">
                {/* ALL BLOG POSTS */}
                <section className="w-full">
                    <div className="container max-w-screen-xl py-20 px-10 md:px-20 lg:px-24 text-darkblue">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 xl:gap-14">
                            {Posts}
                        </div>
                    </div>
                </section>
                {/* VIMEO */}
                <section className="w-full">
                    <div className="container max-w-screen-xl py-20 px-10 md:px-20 lg:px-24 text-darkblue">
                        <div className="border-b border-darkblue pb-3 mb-10">
                            <h2 className="font-arima english-font text-4xl sm:text-3xl md:text-4xl lg:text-5xl">
                                Our videos
                            </h2>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 xl:gap-14">
                            <Vimeo
                                path={'https://vimeo.com/763846583'}
                                img={'/assets/alibaba-gateway.png'}
                                title={'Alibaba Gateway to Growth'}
                                desc={
                                    'Understanding the China opportunity with Ellie Adams, Founder & CEO of QIVA Global'
                                }
                            />
                            <Vimeo
                                path={'https://vimeo.com/579795334'}
                                articlePath="https://www.thedrum.com/news/2021/07/09/how-floradix-cracked-the-china-market-with-its-iron-tonic-marketing-push"
                                img={'/assets/iron-women-main-case.png'}
                                imgAlt={'Iron women main case'}
                                logo={'/assets/drum-award.png'}
                                logoAlt={'Drum award logo'}
                                title={'Beauty is within'}
                                desc={
                                    'QIVA Global’s award-winning marketing campaign for Floradix in China.'
                                }
                                showArticle="true"
                                drumAward="true"
                            />
                            <Vimeo
                                path={
                                    'https://www.vitafoodsinsights.com/video/china-health-wellness-trends-watch-2021-video'
                                }
                                img={'/assets/grass.jpg'}
                                imgAlt={'Think Independently display'}
                                logo={'/assets/vitafoods-asia-logo.png'}
                                logoAlt={'Vitafoods Asia logo'}
                                title={'China health and wellness trends'}
                                desc={
                                    'This video digests emerging data from China’s most recent online shopping festival.'
                                }
                            />
                            <Vimeo
                                path={'https://vimeo.com/460940645'}
                                img={'/assets/health-wellness.jpg'}
                                imgAlt={'woman jogging'}
                                logo={'/assets/cbbs-logo.jpg'}
                                logoAlt={'China-Britain Business Council logo'}
                                logoTwo={'/assets/floradix-logo.svg'}
                                logoAltTwo={'Floradix company logo'}
                                logoTwoClass={'top-10'}
                                title={'Selling to the Health & Wellness Consumer'}
                                desc={
                                    'With a market size > $400Bn USD, the China health & wellness market is hard to ignore.'
                                }
                            />
                            <Vimeo
                                path={'https://vimeo.com/460153096'}
                                img={'/assets/think-independently.jpg'}
                                imgAlt={'red and silver paper boats'}
                                logo={'/assets/cbbs-logo.jpg'}
                                logoAlt={'/assets/China-Britain Business Council logo'}
                                logoTwo={'/assets/woodburn-logo.jpeg'}
                                logoAltTwo={'Woodburn logo'}
                                logoTwoClass={'top-14'}
                                title={'Strategy for the Chinese Market'}
                                desc={
                                    'QIVA Global shares strategy tools used by leading consultancies and demonstrates their application in the Chinese market.'
                                }
                            />
                            <Vimeo
                                path={'https://vimeo.com/460153160'}
                                img={'/assets/qr-scan.jpg'}
                                imgAlt={'QR codes with glass ball'}
                                logo={'/assets/woodburn-logo.jpeg'}
                                logoAlt={'Woodburn logo'}
                                title={'Create a Sales Strategy for the Chinese Market'}
                                desc={
                                    'QIVA Global presents alongside Kristina Coluccia of Woodburn, sharing in-market perspectives of the trade-offs between distribution and eCommerce.'
                                }
                            />
                            <Vimeo
                                path={'https://vimeo.com/460151135'}
                                img={'/assets/protein.jpg'}
                                imgAlt={'woman on an exercise ball'}
                                squareLogo={'/assets/vitafoods-logo.png'}
                                squareLogoAlt={'Vitafoods logo'}
                                title={'Vitafoods Europe 2020: Sports Nutrition in China'}
                                desc={
                                    'QIVA Global presents at Vitafoods on the sports nutrition market opportunity in China, incorporating market analysis, trends and routes to market.'
                                }
                            />
                        </div>
                    </div>
                </section>
                <IdeasWhatWeDo />
            </main>
            <Footer />
        </Layout>
    )
}

export default Ideas

export const pageQuery = graphql`
    query IdeasQuery {
        site {
            siteMetadata {
                title
                description
            }
        }
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        path
                        title
                        thumbnail
                    }
                }
            }
        }
    }
`
