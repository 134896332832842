import React from "react";
import I18nContext from "../lib/i18n-context";
import WhatWeDo from "../components/WhatWeDo";

const IdeasWhatWeDo = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.whatWeDo;

  return (
    <section className="w-full text-darkblue">
      <WhatWeDo
        title={data.title}
        firstTitle={data.services}
        firstImg={"/assets/our-model-display.png"}
        firstAlt={"display design of Our services"}
        firstPath={"/our-services"}
        secondTitle={data.aboutUs}
        secondImg={"/assets/about-us-design.jpg"}
        secondAlt={"display design of About Us"}
        secondPath={"/about-us"}
        thirdTitle={data.work}
        thirdImg={"/assets/why-us-display.png"}
        thirdAlt={"display design of Our work"}
        thirdPath={"/our-work"}
        buttonTitle={data.buttonTitle}
      />
    </section>
  );
};

export default IdeasWhatWeDo;
